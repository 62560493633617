$container-width: 1200px;
$container-wide-width: 1440px;
$container-width-half: $container-width / 2;
$container-wide-width-half: $container-wide-width / 2;
$container-max-width: $container-width;
$grid-columns: 12;
$grid-gutter: 30px;
$grid-gutter-small: 20px;
$grid-gutter-half: $grid-gutter / 2;
$grid-gutter-half-small: $grid-gutter-small / 2;

$bp_sm: 568px;
$bp_md: 768px;
$bp_lg: 1024px;
$bp_xl: 1280px;
