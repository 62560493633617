.faux-radio {
    cursor: pointer;
    color: white;
    padding-right: 10px;
    user-select: none;
    vertical-align: middle;

    &:before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 10px;
        border: 2px solid $color-form-border;
        border-radius: 50%;
        vertical-align: middle;
        }

    :checked + &:before {
        border-color: $color-highlight;
        background-color: $color-highlight;
        }
    }
