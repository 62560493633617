
.thank-you__title {
    margin-bottom: .3em;
    font-size: rem(14);
    text-align: center;
    font-weight: 700;
    color: white;
    }

.thank-you__text {
    margin-bottom: 40px;
    font-size: rem(14);
    font-weight: 500;
    text-align: center;
    color: white;
    }

.thank-you__explanation {
    line-height: 1.4;
    font-size: rem(12);
    font-weight: 500;
    color: $color-font-white;
    }

.question-container {
    margin: 0;
    padding: 0;
    border: 0;

    &::after {
        content: '';
        display: table;
        clear: left;
        }
    }

.question {
    margin: 0 0 20px 0;
    padding: 0;
    color: white;
    font-size: rem(16);
    font-weight: 700;
    }

.answer {
    margin-bottom: 30px;
    }



.submit--next {
    width: 133px;
    height: 52px;
    float: right;
    }


@media screen and (max-width: breakpoint-to-max-width(1040px))
{
    .body--thank-you {
        background: repeat-x rgb(140, 177, 203) url('/assets/images/bg-line.jpg');
        }
}


@media screen and (min-width: 1440px)
{
    .answer {
        margin-bottom: 30px;
        width: 50%;
        float: left;
        }



    .thank-you__title {
        font-size: rem(30);
        }

    .thank-you__text {
        font-size: rem(16);
        }

    .thank-you__explanation {
        font-size: rem(15);
        }

    }
