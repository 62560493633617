.faux-checkbox {
    cursor: pointer;
    color: white;
    padding-right: 10px;
    user-select: none;
    font-size: rem(15);

    &:before {
        content: '✓';
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 10px;
        border: 2px solid $color-form-border;
        border-radius: 3px;
        line-height: 0.7em;
        font-size: 1.1em;
        color: transparent;
        }

    :checked + &:before {
        border-color: $color-highlight;
        color: white;
        text-align: center;
        }
    }

@media screen and (min-width: 1440px)
{
    .faux-checkbox {
        font-size: rem(12);
        }
}
