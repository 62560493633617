.alert {
    max-width: 780px;
    padding: 1em;
    margin: 0 0 1em;
    border: 1px solid transparent;
    border-radius: 1px;

    &--info {
        border-color: $color-alert-info-border;
        background-color: $color-alert-info-background;
        color: $color-alert-info-font;

        &:link,
        &:visited {
            color: $color-alert-info-font-highlight;
            }
        }

    &--warning {
        border-color: $color-alert-warning-border;
        background-color: $color-alert-warning-background;
        color: $color-alert-warning-font;

        &:link,
        &:visited {
            color: $color-alert-warning-font-highlight;
            }
        }

    &--error {
        border-color: $color-alert-error-border;
        background-color: $color-alert-error-background;
        color: $color-alert-error-font;

        &:link,
        &:visited {
            color: $color-alert-error-font-highlight;
            }
        }

    &--success {
        border-color: $color-alert-success-border;
        background-color: $color-alert-success-background;
        color: $color-alert-success-font;

        &:link,
        &:visited {
            color: $color-alert-success-font-highlight;
            }
        }
    &--block {
        display: block;
        }
    }

.alert__list {
    margin: .5em 0 0 0;
    padding-left: 2em;
    line-height: 1.5em;
    }
