*,
*:before,
*:after {
    box-sizing: border-box;
    }

html {
    font-size: #{$root-font-size}px;
    color: $color-font;
    }

html,
body {
    height: 100%;
    }

img {
    max-width: 100%;
    vertical-align: middle;
    }

iframe,
object,
embed {
    max-width: 100%;
    vertical-align: middle;
    }

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 1em 0;
    }

:link,
:visited {
    color: inherit;
    }

p {
    line-height: 1.2em;
    margin-bottom: 1.25em;
    }

.cf::after {
    content: "";
    display: table;
    clear: both;
    }

.is-visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    }


body {
    font-family: $font-copy-fallback;
    font-weight: 400;
    }

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-heading-fallback;
    font-weight: 500;
    }

button {
    cursor: pointer;
    }

a,
button,
input,
select,
textarea {
    &:focus{
        outline: dotted 1px black;
        }
    }

textarea {
    resize: vertical;
    }

.fonts-loaded {
    button,
    input,
    optgroup,
    select,
    textarea,
    body {
        font-family: $font-copy;
        -moz-osx-font-smoothing: grayscale;
        }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $font-heading;
        -moz-osx-font-smoothing: grayscale;
        }

    }
