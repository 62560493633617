.steps {
    width: 440px;
    height: 22px;
    max-width: 100%;
    margin-bottom: 30px;
    margin-top: 5px;
    background-size: 100%;
    background-repeat: no-repeat;

@for $i from 1 through 5
{
    &[data-step="#{$i}"] {
        background-image: url('/assets/images/step-small-#{$i}.svg');
        }
}
    }

@media screen and (min-width: 380px)
{
    .steps {

        @for $i from 1 through 5
        {
            &[data-step="#{$i}"] {
                background-image: url('/assets/images/step-#{$i}.svg');
                }
            }
        }

}
