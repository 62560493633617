$color-primary: rgb(255, 185, 0);
$color-highlight: rgb(2, 190, 133);

$color-bg: rgb(139, 179, 205);

// fonts
$color-font: rgb(0, 0, 0);
$color-font-black: rgb(0, 0, 0);
$color-font-white: rgb(255, 255, 255);

$color-form-border: white;


// alerts

// info
$color-alert-info-border: rgb(188, 223, 241);
$color-alert-info-background: rgb(217, 237, 247);
$color-alert-info-font: rgb(49, 112, 143);
$color-alert-info-font-highlight: rgb(36, 82, 105);

// warning
$color-alert-warning-border: rgb(250, 242, 204);
$color-alert-warning-background: rgb(252, 248, 227);
$color-alert-warning-font: rgb(138, 109, 59);
$color-alert-warning-font-highlight: rgb(102, 81, 44);

// error
$color-alert-error-border: rgb(235, 204, 204);
$color-alert-error-background: rgb(242, 222, 222);
$color-alert-error-font: rgb(169, 68, 66);
$color-alert-error-font-highlight: rgb(132, 53, 52);

// success
$color-alert-success-border: rgb(208, 233, 198);
$color-alert-success-background: rgb(223, 240, 216);
$color-alert-success-font: rgb(60, 118, 61);
$color-alert-success-font-highlight: rgb(43, 84, 44);



