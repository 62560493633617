
.body {
    background: $color-bg no-repeat url('/assets/images/bg-small.jpg') top center;
    display: flex;
    flex-direction: column;
    }

.logo {
    margin-top: 30px;
    margin-bottom: 35px;
    text-align: center;

    & > * {
        width: 52.1875%;
        }
    }

.main {
    display: flex;
    padding: $grid-gutter-half;
    flex-direction: column;
    flex-grow: 1;
    }

.coming-soon {
    text-align: center;
    color: $color-font-white;
    font-size: rem(21);
    font-weight: 700;
    text-transform: uppercase;
    }

.intro {
    max-width: 245px;
    margin: 0 auto;
    text-align: center;
    color: $color-font-white;
    font-size: rem(14);
    font-weight: 700;
    flex-grow: 1;

    & > :first-child {
        margin: 0 0 10px 0;
        text-transform: uppercase;
        font-size: rem(16);
        }

    * + &__part{
        margin: 0;
        }
    }

.preregister-text {
    padding: 1em 1.6em;
    border-radius: 3px;
    background-color: rgba(black, .62);
    text-align: center;
    line-height: 1.3;
    color: $color-font-white;
    font-weight: 500;
    }

.input {
    width: 100%;
    margin-bottom: 11px;
    padding: 15px 1em 11px 1em;
    border: 0;
    border-radius: 3px;
    background-color: white;
    font-size:16px;
    }

.submit {
    width: 100%;
    padding: 8px 8px 9px;
    border: 0;
    border-radius: 3px;
    background-color: $color-highlight;
    color: white;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    font-size: rem(20);
    }

.spam-promise {
    display: block;
    margin-top: 10px;
    margin-bottom: -5px;
    color: white;
    text-align: center;
    font-size: rem(12);
    }

@media screen and (min-width: 375px)
{
    .preregister-text {
        padding: 1em 1.9em;
        line-height: 1.4;
        font-size: 14px;
        }
}


@media screen and (min-width: 568px)
{
    .body {
        background-image: url('/assets/images/bg-medium.jpg');
        }
    .main {
        max-width: 568px;
        margin: 0 auto;
        }
}

@media screen and (min-width: 768px)
{
    .body {
        background-size: cover;
        }
}

@media screen and (min-width: 1024px)
{
    .body {
        padding-left: 50%;
        padding-top: 106px;
        background-image: url('/assets/images/bg-large.jpg');
        background-size: auto;
        background-position: 27% -65px;
        }

    .logo {
        max-width: 440px;
        margin-bottom: 68px;
        & > * {
            width: auto;
            }
        }

    .main {
        max-width: 480px;
        margin: 0;
        padding: 0;
        }

    .main--home {
        max-width: 440px;
        max-height: 385px;
        }

    .coming-soon {
        margin-bottom: 68px;
        text-align: left;
        font-size: rem(40);
        letter-spacing: .12em;
        }

    .intro {
        max-width: 22em;
        margin: 0;
        font-size: rem(18);

        & > :first-child {
            font-size: rem(22);
            }
        }

    .preregister-text {
        margin-top: 1.5em;
        text-align: left;
        font-size: rem(15);
        }

    .input {
        max-width: 287px;
        margin-right: 5px;
        margin-bottom: 0;
        padding: 17px 1em 15px 1em;
        font-size: rem(18);
        }

    .submit {
        width: auto;
        padding: 15px 14px 14px;
        }

    .spam-promise {
        margin-bottom: 10px;
        }
}

@media screen and (min-width: 1440px)
{
    .body {
        padding-top: 166px;
        background-position: top center;
        }
}

@media screen and (min-width: 1800px)
{
    .body {
        background-size: cover;
        }
}
